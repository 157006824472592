import React, { forwardRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { CartProvider, useCart } from 'react-use-cart';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import countries from 'countries-list';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../components/Layout';
import Link from '/src/components/LocalizedLink';
import Dropdown from '../components/Dropdown';

import img4WeekAcc from '../assets/images/course-selection-form/4 Weeks Accommodation.png';
import imgAirPickup from '../assets/images/course-selection-form/Airport Pick Up.png';
import imgCulturalActivity from '../assets/images/course-selection-form/Cultural Activity.png';
import imgEmpadroniamento from '../assets/images/course-selection-form/Empadronamiento Service.png';
import imgExpression from '../assets/images/course-selection-form/Expression of Interest.png';
import imgNIE from '../assets/images/course-selection-form/NIE & TIE Service.png';
import imgTrinityInPerson from '../assets/images/course-selection-form/Trinity CertTESOL In Person.png';
import imgTrinityInPerson1 from '../assets/images/course-selection-form/Trinity CertTESOL In Person-1.png';
import imgTeacherAndSpanishCourse from '../assets/images/course-selection-form/kenny-eliason-zFSo6bnZJTw-unsplash.jpg';
import imgCalendar from '../assets/images/course-selection-form/mycalendar.svg';
import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import imgIntensiveSpanish from '../assets/images/student-visas/photo 1 (5).png';

import 'react-datepicker/dist/react-datepicker.css';
import '../assets/styles/pages/course-selection-form-page.scss';

const FieldValidationErrorMsg = ({ field }) =>
  field ? <div className="error-val">{field.message}</div> : null;

const COURSES = {
  'Trinity CertTESOL In Person': {
    title: 'Trinity CertTESOL In Person',
    description: '4-week, 160-hour, in-person Trinity CertTESOL course.',
    price: 1750,
    thumb: imgTrinityInPerson,
    backgroundColor: '#f5f9fc',
    zohoItemId: '3197252000000101532',
    required: true,
  },
  // 'Developing Teacher Course': {
  //   6: {
  //     title: 'Developing Teacher Course',
  //     description: '6 or 12 months Developing Teacher Course. 6 months 180€ or 12 months 300€.',
  //     price: 180,
  //     thumb: imgTrinityInPerson1,
  //     backgroundColor: '#f5f9fc',
  //     zohoItemId: '3197252000000430293',
  //   },
  //   12: {
  //     title: 'Developing Teacher Course',
  //     description: '6 or 12 months Developing Teacher Course. 6 months 180€ or 12 months 300€.',
  //     price: 300,
  //     thumb: imgTrinityInPerson1,
  //     backgroundColor: '#f5f9fc',
  //     zohoItemId: '3197252000000430293',
  //   },
  // },
  'Developing Teacher & Spanish Course': {
    6: {
      title: 'Developing Teacher & Spanish Course',
      description:
        '6 or 12 months Developing Teacher & Spanish Course. 6 months 2000€ or 12 months 3000€',
      price: 2000,
      thumb: imgTeacherAndSpanishCourse,
      backgroundColor: '#f9f2fa',
      zohoItemId: '3197252000000430293',
    },
    12: {
      title: 'Developing Teacher & Spanish Course',
      description:
        '6 or 12 months Developing Teacher & Spanish Course. 6 months 2000€ or 12 months 3000€',
      price: 3200,
      thumb: imgTeacherAndSpanishCourse,
      backgroundColor: '#f9f2fa',
      zohoItemId: '3197252000000430293',
    },
  },
  '4 Weeks Accommodation Barcelona': {
    title: '4 Weeks Accommodation Barcelona',
    description: '4 weeks accommodation in a central Barcelona shared flat.',
    price: 750,
    thumb: img4WeekAcc,
    backgroundColor: '#f4fcf0',
    zohoItemId: '3197252000000101633',
  },

  '12 Weeks Accommodation in Barcelona': {
    title: '12 Weeks Accommodation in Barcelona',
    description:
      '12 weeks of accommodation in a central Barcelona shared flat. Save 300€ and the stress of finding a flat during your course.',
    price: 1950,
    thumb: img4WeekAcc,
    backgroundColor: '#f4fcf0',
    zohoItemId: '3197252000002214001',
  },

  'Airport Pick Up': {
    title: 'Airport Pick Up',
    description: 'Airport transfer to your accomodation.',
    price: 70,
    thumb: imgAirPickup,
    backgroundColor: '#fffded',
    zohoItemId: '3197252000000101642',
  },

  'Empadronamiento Service': {
    title: 'Empadronamiento Service',
    description: 'Empadronamiento appointment and paperwork assistance.',
    price: 40,
    thumb: imgEmpadroniamento,
    backgroundColor: '#f0effc',
    zohoItemId: '3197252000000101939',
  },

  'NIE & TIE Service': {
    title: 'NIE & TIE Service',
    description: 'NIE & TIE appointment and paperwork assistance.',
    price: 120,
    thumb: imgNIE,
    backgroundColor: '#f8ecec',
    zohoItemId: '3197252000000104146',
  },

  'Cultural Activity': {
    title: 'Cultural Activity',
    description:
      'We organise a range of activities including walking tours, paella cooking classes, flamenco shows, etc.',
    price: 50,
    thumb: imgCulturalActivity,
    backgroundColor: '#f4fcf0',
    zohoItemId: '3197252000000101624',
  },

  'One Month Spanish Course': {
    title: 'One Month Spanish Course',
    description:
      '10 hours per week of Spanish after your TEFL course. 09.30-11.30, Monday-Friday. Regular price is 360€, early booking price is 260€. Includes course book.',
    price: 260,
    thumb: imgIntensiveSpanish,
    backgroundColor: '#f5f9fc',
    zohoItemId: '3197252000002543001',
  },
};

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const promocodesList = [
  { code: 'Discount50', number: 50, type: 'percentage' },
  { code: 'Fixed1Euro', number: 1, type: 'fixed' },
  { code: 'Devhance1Euro', number: 1, type: 'installable' },
  { code: 'Friend100', number: 100, type: 'fixed' },
];

const ACTIVE_SPECIAL_OFFER_DISCOUNT = 60;
const FULL_PRICE_SPECIAL_OFFER_DISCOUNT = 100;
const SUPER_SPECIAL_DISCOUNT = 0;
const PAYMENT_BY_INSULMENTS_VALUE = 450;

const CourseSelectionForm = ({ data }) => {
  const [selectedCourseTitles, setSelectedCourseTitles] = useState([]); // ['Developing Teacher & Spanish Course', 'Developing Teacher Course']
  const [selectedPeriods, setSelectedPeriods] = useState({
    'Developing Teacher Course': { period: '6' },
    'Developing Teacher & Spanish Course': { period: '6' },
  }); // { 'Developing Teacher & Spanish Course': { period: '12' }  }

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(null);
  const { register, handleSubmit, setValue, errors, control } = useForm();
  const [intendedStartDate, setIntendedStartDate] = useState(new Date());
  const [stage, setStage] = useState('courses'); // "courses" | "summary"
  const [tempData, setTempData] = useState(() => {
    if (typeof window === 'object') {
      return JSON.parse(localStorage?.getItem('tempFormData')) ?? null;
    }
    return null;
  });

  useEffect(() => {
    localStorage.setItem('tempFormData', JSON.stringify(tempData));
  }, [tempData]);

  const onNextStage = () => {
    if (stage === 'courses') return setStage('summary');
  };

  const onPrevStage = () => {
    if (stage === 'summary') return setStage('courses');
  };

  const [paymentOffer, setPaymentOffer] = useState({
    payment: 'fullPrice',
    appliedDeposit: false,
    isActive: false,
  });

  const [promocodeName, setPromocodeName] = useState('');
  const [promocode, setPromocode] = useState(null);

  const activeSuperSpecialDiscount =
    selectedPeriods['Developing Teacher & Spanish Course'].period === '12' &&
    selectedCourseTitles.includes('Trinity CertTESOL In Person');
  console.log('activeSuperSpecialDiscount: ', activeSuperSpecialDiscount);

  const activeSpecialDiscount =
    selectedCourseTitles.includes('Developing Teacher Course') &&
    selectedPeriods['Developing Teacher Course'].period === '12';

  const calcTotal = (appliedDeposit = false) => {
    let fPrice = selectedCourseTitles.reduce((acc, title) => {
      const itemHasQuantity = Object.keys(COURSES[title]).every((key) => /[0-9]+/.test(key));

      const selectedItemQuantity = selectedPeriods[title]?.period;

      const item = itemHasQuantity ? COURSES[title][selectedItemQuantity] : COURSES[title];
      return acc + item.price;
    }, 0);

    if (promocode?.active) {
      if (promocode.type === 'percentage') {
        fPrice = (fPrice - (fPrice / 100) * promocode.number).toFixed(2);
      }
      if (promocode.type === 'fixed') {
        fPrice = (fPrice - promocode.number).toFixed(2);
      }
      if (promocode.type === 'installable') {
        fPrice = promocode.number;
      }
    }

    const checkTotalPayment =
      paymentOffer?.payment === 'fullPrice' && fPrice <= FULL_PRICE_SPECIAL_OFFER_DISCOUNT;

    if (appliedDeposit) return PAYMENT_BY_INSULMENTS_VALUE;

    if (activeSuperSpecialDiscount) fPrice = fPrice - SUPER_SPECIAL_DISCOUNT;

    if (activeSpecialDiscount)
      return checkTotalPayment
        ? fPrice
        : fPrice - FULL_PRICE_SPECIAL_OFFER_DISCOUNT - ACTIVE_SPECIAL_OFFER_DISCOUNT;

    return checkTotalPayment ? fPrice : fPrice - FULL_PRICE_SPECIAL_OFFER_DISCOUNT;
  };

  const total = calcTotal(paymentOffer.appliedDeposit);

  const handleSubmitPromocode = async () => {
    if (promocodeName && !promocode?.active) {
      let findPromocode = promocodesList.find(
        (item) => item.code.toLowerCase() === promocodeName.toLowerCase()
      );

      if (findPromocode) {
        setPromocode({ ...findPromocode, active: true });
        return;
      }
      setPromocode({ error: true });
    }
  };

  const addToCRM = async (data) => {
    const crmData = {
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
      country: data.country.value,
      startDate: intendedStartDate,
      promo: promocode?.code ?? '',
      items: selectedCourseTitles.map((title) => ({
        courseTitle: title,
        quantity: selectedPeriods[title]?.period ?? 1,
      })),
      paymentOffer: paymentOffer?.payment === 'deposit' ? 'Deposit' : 'Full Payment',
      userFromGoogleAds: window?.userFromGoogleAds,
      userFromFacebookAds: window?.userFromFacebookAds,
      userFromGoOverseas: window?.userFromGoOverseas,
      userFromGoAbroad: window?.userFromGoAbroad,
    };

    const response = await fetch('/api/course-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(crmData),
    });
    const jsonResponse = await response.json();

    if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
      const MailchimpData = {
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE4: new Date().toISOString().split('T')[0], // contact date
        MMERGE17: 'Application form', // Source
      };
      addToMailchimp(data.userEmail, MailchimpData);
    } else {
      console.log('Monday Error', jsonResponse);
      throw new Error(`Monday Error: ${JSON.stringify(jsonResponse.error)}`);
    }
  };

  const calculateDiscount = () => {
    let discount = 0;

    if (activeSuperSpecialDiscount) {
      discount += SUPER_SPECIAL_DISCOUNT;
    }
    if (activeSpecialDiscount) {
      discount += ACTIVE_SPECIAL_OFFER_DISCOUNT;
    }

    return discount + FULL_PRICE_SPECIAL_OFFER_DISCOUNT;
  };

  const addToZoho = async (data) => {
    const getAdjustment = () => {
      const developingTeacherInCart = selectedCourseTitles.includes('Developing Teacher Course');

      const isDevTSpecial =
        developingTeacherInCart &&
        selectedPeriods['Developing Teacher & Spanish Course'].period === '12';

      if (isDevTSpecial && activeSuperSpecialDiscount && paymentOffer.payment === 'deposit')
        return (
          -(calcTotal() + FULL_PRICE_SPECIAL_OFFER_DISCOUNT + SUPER_SPECIAL_DISCOUNT) +
          PAYMENT_BY_INSULMENTS_VALUE
        );
      else if (activeSuperSpecialDiscount && paymentOffer.payment === 'deposit') {
        return (
          -(calcTotal() + FULL_PRICE_SPECIAL_OFFER_DISCOUNT + SUPER_SPECIAL_DISCOUNT) +
          PAYMENT_BY_INSULMENTS_VALUE
        );
      } else if (paymentOffer.payment === 'deposit') {
        return -(calcTotal() + FULL_PRICE_SPECIAL_OFFER_DISCOUNT) + PAYMENT_BY_INSULMENTS_VALUE;
      }

      if (activeSuperSpecialDiscount) {
        return -(FULL_PRICE_SPECIAL_OFFER_DISCOUNT + SUPER_SPECIAL_DISCOUNT);
      }
      return -FULL_PRICE_SPECIAL_OFFER_DISCOUNT;
    };

    const reqZohoData = {
      contact_name: data.firstName + ' ' + data.lastName,
      first_name: data.firstName,
      last_name: data.lastName,
      student_email: data.userEmail,
      country: data.country.value,
      discount: tempData.discount,
      adjustment: getAdjustment(),
      adjustment_description: paymentOffer.appliedDeposit ? 'deposit' : 'full_payment',
      line_items: selectedCourseTitles.map((title) => {
        const itemHasQuantity = Object.keys(COURSES[title]).every((key) => /[0-9]+/.test(key));

        const selectedItemQuantity = selectedPeriods[title]?.period;

        const item = itemHasQuantity ? COURSES[title][selectedItemQuantity] : COURSES[title];

        if (item.title === 'Developing Teacher Course')
          return {
            item_id: item.zohoItemId,
            description: item.description,
            rate: item.price,
            quantity: 1,
            discount:
              selectedPeriods[item.title]?.period === '12' ? ACTIVE_SPECIAL_OFFER_DISCOUNT : 0,
          };

        return {
          item_id: item.zohoItemId,
          description: item.description,
          rate: item.price,
          quantity: 1,
          discount_amount: 666,
        };
      }),
    };

    const responseZoho = await fetch('/api/zoho/invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqZohoData),
    });

    const responseZohoJSON = await responseZoho.json();

    if (responseZohoJSON?.invoice_url && responseZohoJSON?.message === 'Success') {
      setError(false);
      window.fbq('track', 'SubmitApplication');
      window.location.href = responseZohoJSON.invoice_url;
      // addToMailchimp(data.userEmail, MailchimpData);
    } else {
      console.log('Zoho Error', responseZohoJSON);
      throw new Error(`Zoho Error: ${JSON.stringify(responseZohoJSON.error)}`);
    }
  };

  const onSubmit = async (data) => {
    setTempData({
      ...data,
      discount: calculateDiscount(),
      adjustment: 0 - calculateDiscount(),
      finalPrice: total,
      lineItems: selectedCourseTitles.map((title) => {
        const itemHasQuantity = Object.keys(COURSES[title]).every((key) => /[0-9]+/.test(key));

        const selectedItemQuantity = selectedPeriods[title]?.period;

        const item = itemHasQuantity ? COURSES[title][selectedItemQuantity] : COURSES[title];

        return {
          title: item.title,
          price: item.price,
          quantity: selectedPeriods[item.title]?.period ?? 1,
        };
      }),
    });
    onNextStage();
    if (stage !== 'summary') return;

    // Enable loading
    setIsLoading(true);

    try {
      await addToCRM(tempData);
      await addToZoho(tempData);
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setError(error ? error.message || error : 'Oops, something went wrong');
    } finally {
      setIsLoading(false);
      setTempData(null);
    }
  };

  return (
    <FormLayout
      handleSubmitPromocode={handleSubmitPromocode}
      promocodeName={promocodeName}
      setPromocodeName={setPromocodeName}
      intendedStartDate={intendedStartDate}
      setIntendedStartDate={setIntendedStartDate}
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      onSubmit={onSubmit}
      finalPrice={total}
      paymentOffer={paymentOffer}
      setPaymentOffer={setPaymentOffer}
      stage={stage}
      onPrevStage={onPrevStage}
      tempData={tempData}
      activeSuperSpecialDiscount={activeSuperSpecialDiscount}
      setPromocode={setPromocode}
      courses={COURSES}
      selectedPeriods={selectedPeriods}
      setSelectedPeriods={setSelectedPeriods}
      selectedCourseTitles={selectedCourseTitles}
      setSelectedCourseTitles={setSelectedCourseTitles}
    />
  );
};

const FormLayout = ({
  handleSubmitPromocode,
  promocodeName,
  setPromocodeName,
  intendedStartDate,
  setIntendedStartDate,
  register,
  control,
  handleSubmit,
  errors,
  isError,
  isSuccess,
  isLoading,
  onSubmit,
  finalPrice,
  paymentOffer,
  setPaymentOffer,
  stage,
  onPrevStage,
  tempData,
  setPromocode,
  courses,
  selectedPeriods,
  setSelectedPeriods,
  selectedCourseTitles,
  setSelectedCourseTitles,
}) => {
  console.log('paymentOffer: ', paymentOffer);
  console.log('tempData: ', tempData);

  const submitBtnText = stage === 'courses' ? 'Go To Summary' : 'Go To Payment';
  return (
    <Layout
      title="Learn to Teach English (TEFL) in Barcelona! Apply Now"
      description="Become the TEFL teacher you want to be by applying for TEFL Iberia's friendly"
    >
      <div className="course-selection-form-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">Course Selection Form</h1>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <form className="forms" onSubmit={handleSubmit(onSubmit)}>
              {stage === 'courses' && (
                <div className="form">
                  <div>
                    <label className="form__first-name">
                      <p className="c-text-14 text-under">First Name</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="John"
                        name="firstName"
                        defaultValue={tempData?.firstName ?? ''}
                        ref={register({
                          required: 'This field is required.',
                        })}
                      />
                      <FieldValidationErrorMsg field={errors.firstName} />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Last Name</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="Smith"
                        name="lastName"
                        defaultValue={tempData?.lastName ?? ''}
                        ref={register({
                          required: 'This field is required.',
                        })}
                      />
                      <FieldValidationErrorMsg field={errors.lastName} />
                    </label>
                    <label className="form__first-name form__first-name--block">
                      <p className="c-text-14 text-under">Email address*</p>
                      <input
                        className="input__first-name"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        defaultValue={tempData?.userEmail ?? ''}
                        name="userEmail"
                        ref={register({
                          required: 'This field is required.',
                        })}
                      />
                      <FieldValidationErrorMsg field={errors.userEmail} />
                    </label>

                    <label className="form__first-name form__first-name--block">
                      <p className="c-text-14 text-under">Country*</p>
                      <Controller
                        name="country"
                        render={({ ref, ...props }) => (
                          <Dropdown
                            inputRef={ref}
                            options={countryNamesOptions}
                            placeholder="Select your country"
                            isSearchable
                            {...props}
                          />
                        )}
                        control={control}
                        defaultValue={tempData?.country ?? null}
                        rules={{
                          required: 'This field is required.',
                        }}
                      />
                      <FieldValidationErrorMsg field={errors.userNationality} />
                    </label>

                    <div className="devider-line"></div>

                    <h1 className="c-title-22 form__title">Intended Start Date</h1>
                    <div className="start-end-wrapper">
                      <div className="start">
                        <h3 className="c-text-14 text-under">Start date</h3>
                        <DatePicker
                          selected={intendedStartDate}
                          onChange={(date) => setIntendedStartDate(date)}
                          customInput={<CustomDateInput />}
                          startDate={intendedStartDate}
                        />
                      </div>
                    </div>
                    <p className="form__subtitle">
                      Please select the course and any additional services you would like. Discounts
                      can be applied at the end.
                    </p>
                  </div>

                  <div className="cards-list">
                    {Object.entries(courses).map(([key, value], index) => {
                      const itemHasQuantity = Object.keys(value).every((key) => /[0-9]+/.test(key));

                      const selectedItemQuantity = selectedPeriods[key]?.period;

                      const item = itemHasQuantity ? value[selectedItemQuantity] : courses[key];

                      const cardId = `card-${item.zohoItemId}`;
                      return (
                        <div key={index}>
                          {index === 2 && <h3 className="form__subtitle">Additional services:</h3>}
                          <div className="card" key={item.zohoItemId}>
                            <label
                              style={{ backgroundColor: item.backgroundColor }}
                              className="card__wrapper"
                            >
                              <img className="card__thumb" src={item.thumb} alt={item.title} />
                              <div className="card__content">
                                <div>
                                  <input
                                    id={cardId}
                                    type="checkbox"
                                    checked={selectedCourseTitles.includes(item.title)}
                                    name="course"
                                    ref={register}
                                    required={item.required}
                                    onChange={() => {
                                      setSelectedCourseTitles((prev) =>
                                        prev.includes(item.title)
                                          ? prev.filter((i) => i !== item.title)
                                          : [...prev, item.title]
                                      );
                                      setPromocode(null);
                                      setPaymentOffer((prev) => ({
                                        ...prev,
                                        isActive: false,
                                        appliedDeposit: false,
                                      }));
                                    }}
                                  />
                                  <div className="card__label">
                                    {item.required && (
                                      <p className="card__warning">
                                        Please select Trinity CertTESOL in Person or online to
                                        continue
                                      </p>
                                    )}
                                    <h3 className="card__title">{item.title}</h3>
                                    <p className="card__description">{item.description}</p>
                                  </div>
                                </div>
                                <div className="card__meta">
                                  <p className="card__price">
                                    &euro;
                                    {item.price}
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="card__meta card__meta--length">
                              {itemHasQuantity && (
                                <div className="month-container">
                                  <p className="month">Months</p>
                                  <Dropdown
                                    options={Object.keys(value).map((key) => ({
                                      value: key,
                                      label: key,
                                    }))}
                                    name="courseLength"
                                    placeholder={'Select'}
                                    className="card__dropdown"
                                    onChange={(data) =>
                                      setSelectedPeriods((prevState) => ({
                                        ...prevState,
                                        [item.title]: { period: data.value },
                                      }))
                                    }
                                    value={{
                                      value: selectedPeriods[item.title].period,
                                      label: selectedPeriods[item.title].period,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            {item.required && <FieldValidationErrorMsg field={errors.agreeRules} />}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="devider-line" />

                  <label className="form__first-name promotional-code">
                    <p className="c-text-14 text-under">
                      Do you have a referral or promotion code?
                    </p>
                    <input
                      className="input__first-name"
                      type="text"
                      placeholder="Please enter your code or leave blank."
                      name="promotionalCode"
                      value={promocodeName}
                      onChange={({ target: { value } }) => setPromocodeName(value)}
                    />
                    <button
                      type="button"
                      className="promotional-code__btn"
                      onClick={handleSubmitPromocode}
                    >
                      Apply Promotional Code
                    </button>
                  </label>

                  <h3 className="form__subtitle"> I want to pay*</h3>
                  <div className="form__radio">
                    <input
                      id="course-radio-1"
                      type="radio"
                      name="course"
                      value="fullPrice"
                      checked={paymentOffer.payment === 'fullPrice'}
                      onChange={() =>
                        setPaymentOffer({
                          payment: 'fullPrice',
                          isActive: true,
                          appliedDeposit: false,
                        })
                      }
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="course-radio-1">
                      <span className="label">
                        {' '}
                        I would like to pay in full today and save{' '}
                        <b>&euro;{FULL_PRICE_SPECIAL_OFFER_DISCOUNT}</b>
                      </span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="course-radio-2"
                      type="radio"
                      name="course"
                      value="deposit"
                      checked={paymentOffer.payment === 'deposit'}
                      onChange={() => setPaymentOffer({ payment: 'deposit', appliedDeposit: true })}
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="course-radio-2">
                      <span className="label">
                        I would like to pay a deposit of <b>&euro;{PAYMENT_BY_INSULMENTS_VALUE}</b>
                      </span>
                    </label>
                  </div>

                  <div className="devider-line"></div>
                  <div className="total">
                    <h3>
                      Total:{' '}
                      <span className="total__price">
                        &euro;{' '}
                        {paymentOffer.appliedDeposit
                          ? PAYMENT_BY_INSULMENTS_VALUE
                          : finalPrice || 0}
                      </span>
                    </h3>
                  </div>
                  <div className="form__checkbox terms">
                    <input
                      id="agree-checkbox-1"
                      type="checkbox"
                      required
                      value={true}
                      name="agreeRules"
                      defaultChecked={tempData?.agreeRules ?? false}
                      ref={register({ required: 'This field is required.' })}
                    />
                    <label htmlFor="agree-checkbox-1">
                      <span className="label">
                        I have read and agree with TEFL Iberia's
                        <Link to="/apply/terms" className="red-link">
                          {' '}
                          Terms and Conditions
                        </Link>{' '}
                      </span>
                    </label>
                    <FieldValidationErrorMsg field={errors.agreeRules} />
                  </div>
                </div>
              )}

              {stage === 'summary' && (
                <div className="form">
                  <button onClick={onPrevStage} className="btn-go-back">
                    <IconArrowRight className="go-back-arrow" /> Go back
                  </button>
                  <h1 className="c-title-22 form__title">Summary</h1>
                  <div className="summary-block">
                    <h3 className="form__subtitle">Courses & Service:</h3>
                    {tempData?.lineItems?.map((item) => {
                      return (
                        <p className="form__text">
                          Title:{' '}
                          <b className="accent">
                            {item.title}{' '}
                            {item.title === 'Developing Teacher Course' ? (
                              <span>({item.quantity} - months)</span>
                            ) : item.title === 'Developing Teacher & Spanish Course' ? (
                              <span>({item.quantity} - months)</span>
                            ) : (
                              ''
                            )}
                          </b>{' '}
                          <br />
                          Price:{' '}
                          <b className="accent">
                            &euro;
                            {item.title === 'Developing Teacher Course' && item.quantity === '12'
                              ? item.price - ACTIVE_SPECIAL_OFFER_DISCOUNT
                              : item.price}
                          </b>
                          <br />
                        </p>
                      );
                    })}
                  </div>
                  <div className="summary-block">
                    <h3 className="form__subtitle">Contact Information:</h3>
                    <p className="form__text">
                      First name: <b className="accent">{tempData?.firstName}</b>
                    </p>
                    <p className="form__text">
                      Last name: <b className="accent">{tempData?.lastName}</b>
                    </p>
                    <p className="form__text">
                      Email address: <b className="accent">{tempData?.userEmail}</b>
                    </p>
                    <p className="form__text">
                      Current location: <b className="accent">{tempData?.country?.label}</b>
                    </p>
                    {/* <p className="form__text">
                      Nationality: <b className="accent">{}</b>
                    </p> */}
                  </div>
                  <div className="summary-block">
                    {paymentOffer?.payment === 'deposit' ? (
                      <p className="form__text">
                        Price:{' '}
                        {paymentOffer?.payment === 'deposit' && <b className="accent">&euro;450</b>}
                      </p>
                    ) : (
                      <>
                        {' '}
                        <h3 className="form__subtitle">
                          Price:{' '}
                          {paymentOffer?.payment === 'deposit' && (
                            <b className="accent">&euro;450</b>
                          )}
                        </h3>
                        <p className="form__text">
                          Subtotal:{' '}
                          <b className="accent">
                            &euro;
                            {tempData?.finalPrice + tempData?.discount}
                          </b>
                        </p>
                        <p className="form__text">
                          Discount: <b className="accent">&euro;{tempData?.discount}</b>
                        </p>
                      </>
                    )}
                  </div>

                  <h4 className="summary-total">
                    Payable now:{' '}
                    {paymentOffer?.payment === 'deposit' ? (
                      <b className="accent">&euro;450</b>
                    ) : (
                      <b className="accent">&euro;{tempData?.finalPrice}</b>
                    )}
                  </h4>
                  {/* <p className="deposit accent">Pay a 350€ deposit to book your place </p> */}
                </div>
              )}

              <button
                type="submit"
                className="c-btn c-btn--red"
                // disabled={Object.keys(errors).length > 0}
                disabled={isLoading}
              >
                {isLoading ? <i className="spinner" /> : submitBtnText}
              </button>

              {isSuccess && (
                <div className="success-msg">
                  <span> Your application has been successfully submitted!</span>
                </div>
              )}

              {isError && (
                <div className="error-msg">
                  <span>{JSON.stringify(isError)}</span>
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="error-msg">
                  <span>Some fields are missing or contain errors.</span>
                </div>
              )}
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" type="button" onClick={onClick} ref={ref}>
    <span className="icon-wrapper">
      <img src={imgCalendar} alt={'Calendar'} />
    </span>
    {value}
  </button>
));

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            name {
              text
            }
          }
        }
      }
    }
  }
`;

const CourseSelectionFormContainer = ({ data }) => (
  <CartProvider id="course-selection-form">
    <CourseSelectionForm data={data} />
  </CartProvider>
);

export default CourseSelectionFormContainer;
