import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from '/src/components/LocalizedLink';

import imgRmain from '../assets/images/application-process/Photo 1 (7).png';
import imgTrinity from '../assets/images/application-process/Trinity college london (2).png';
import imgDots from '../assets/images/application-process/Dots 3.svg';

const AplicationProcess = ({
  isOnlineCourse = false,
  renderThirdContent,
  renderFourthTitle,
  renderFourthContent,
  renderFifthTitle,
  renderFifthContent,
  renderTitle,
}) => {
  return (
    <div className="c-application-process-section">
      <div className="container">
        <h1 className="c-title-34">
          {renderTitle ? renderTitle : <FormattedMessage id="appProcess.title" />}
        </h1>
        <div className="row">
          <div className="col-xl-5 col-lg-4 col-sm-6 col-12">
            <div className="left">
              <div className="left__wrapper">
                <div className="circle">
                  <span>1</span>
                </div>
                <div className="left__text">
                  <h3 className="c-title-18">
                    <FormattedMessage id="appProcess.ben1.title" />
                  </h3>
                  <p className="c-text-14 hidden">
                    <FormattedMessage
                      id="appProcess.ben1.text"
                      values={{
                        accent: (chunk) => (
                          <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.entry-requirements">
                            {(locale) => (
                              <Link className="red-link" to={locale[0]}>
                                {chunk}
                              </Link>
                            )}
                          </FormattedMessage>
                        ),
                        accent2: (chunk) => (
                          <FormattedMessage id="url.apply.how-to-make-a-good-application">
                            {(locale) => (
                              <Link className="red-link" to={locale[0]}>
                                {chunk}
                              </Link>
                            )}
                          </FormattedMessage>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className="left__wrapper">
                <div className="circle">
                  <span>2</span>
                </div>

                <div className="left__text">
                  <h3 className="c-title-18">
                    <FormattedMessage id="appProcess.ben2.title" />
                  </h3>
                  <p className="c-text-14 hidden">
                    <FormattedMessage id="appProcess.ben2.text" />
                  </p>
                </div>
              </div>
              <div className="left__wrapper">
                <div className="circle">
                  <span>3</span>
                </div>
                <div className="left__text">
                  <h3 className="c-title-18">
                    <FormattedMessage id="appProcess.ben3.title" />
                  </h3>
                  <p className="c-text-14 hidden">
                    {renderThirdContent ? (
                      renderThirdContent
                    ) : (
                      <FormattedMessage id="appProcess.ben3.text" />
                    )}
                  </p>
                </div>
              </div>
              <div className="left__wrapper">
                <div className="circle">
                  <span>4</span>
                </div>
                <div className="left__text">
                  {renderFourthTitle ? (
                    <>
                      <h3 className="c-title-18">{renderFourthTitle}</h3>
                      <p className="c-text-14 hidden">{renderFourthContent}</p>
                    </>
                  ) : (
                    <>
                      <h3 className="c-title-18">
                        <FormattedMessage id="appProcess.ben4.title" />
                      </h3>
                      <p className="c-text-14 hidden">
                        <FormattedMessage id="appProcess.ben4.text" />
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="left__wrapper">
                <div className="circle">
                  <span>5</span>
                </div>
                <div className="left__text">
                  {renderFifthTitle ? (
                    <>
                      <h3 className="c-title-18">{renderFifthTitle}</h3>
                      <p className="c-text-14 hidden">{renderFifthContent}</p>
                    </>
                  ) : (
                    <>
                      <h3 className="c-title-18">
                        <FormattedMessage id="appProcess.ben5.title" />
                      </h3>
                      <p className="c-text-14 hidden">
                        {!isOnlineCourse ? (
                          <FormattedMessage id="appProcess.ben5.text" />
                        ) : (
                          <FormattedMessage id="appProcess.ben5.text.onlineCourse" />
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
            <div className="center">
              <p className="c-text-14">
                <FormattedMessage
                  id="appProcess.ben1.text"
                  values={{
                    accent: (chunk) => (
                      <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.entry-requirements">
                        {(locale) => (
                          <Link className="red-link" to={locale[0]}>
                            {chunk}
                          </Link>
                        )}
                      </FormattedMessage>
                    ),
                    accent2: (chunk) => (
                      <FormattedMessage id="url.apply.how-to-make-a-good-application">
                        {(locale) => (
                          <Link className="red-link" to={locale[0]}>
                            {chunk}
                          </Link>
                        )}
                      </FormattedMessage>
                    ),
                  }}
                />
              </p>
              <p className="c-text-14">
                <FormattedMessage id="appProcess.ben2.text" />
              </p>
              <p className="c-text-14">
               {renderThirdContent ? renderThirdContent : <FormattedMessage id="appProcess.ben3.text" />}
              </p>
              <p className="c-text-14">
                {renderFourthContent ? (
                  renderFourthContent
                ) : (
                  <FormattedMessage id="appProcess.ben4.text" />
                )}
              </p>
              <p className="c-text-14">
                {renderFifthContent ? (
                  renderFifthContent
                ) : !isOnlineCourse ? (
                  <FormattedMessage id="appProcess.ben5.text" />
                ) : (
                  <FormattedMessage id="appProcess.ben5.text.onlineCourse" />
                )}
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4">
            <div className="right">
              <img src={imgRmain} alt="Richard Davie" className="right__img" />
              <div className="trinity">
                <img src={imgTrinity} alt="imgTrinity" />
              </div>
              <img src={imgDots} alt="dots" className="dots" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AplicationProcess;
