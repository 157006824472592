import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';
import AplicationProcess from '../components/AplicationProcess';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import iconTick from '../assets/images/svg/tick.svg';
import iconAccomodation from '../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../assets/images/gap-year-program/dedicated-support-team.svg';
import iconCulture from '../assets/images/gap-year-program/emergency.svg';
import iconBureaucracy from '../assets/images/student-support/Bureaucracy.svg';
import iconEnvironment from '../assets/images/gap-year-program/supportive-enviroment.svg';

import imgBenefit1 from '../assets/images/trinity-cert-tesol-course-page/Internationally Recognized Qualification (1).svg';
import imgBenefit2 from '../assets/images/gap-year-program/fluent-speaking.svg';
import imgBenefit3 from '../assets/images/trinity-cert-tesol-course-page/Small Group Sizes  (1).svg';
import imgBenefit5 from '../assets/images/gap-year-program/fun-activities.svg';
import imgReview from '../assets/images/gap-year-program/test.jpg';
import iconQuotes from '../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgGrid1 from '../assets/images/trinity-cert-tesol-course-page/seventh.jpg';
import imgGrid2 from '../assets/images/trinity-cert-tesol-course-page/eighth.png';
import imgGrid3 from '../assets/images/trinity-cert-tesol-course-page/Photo 2 (2).png';
import imgGrid4 from '../assets/images/gap-year-program/banner1.jpg';
import imgGrid5 from '../assets/images/trinity-cert-tesol-course-page/Image3.jpeg';
import imgGrid6 from '../assets/images/gap-year-program/banner2.jpg';
import iconCalendar from '../assets/images/svg/calendar.svg';
import iconClock from '../assets/images/svg/clock.svg';
import iconColumn from '../assets/images/trinity-cert-tesol-course-page/column.svg';

import '../assets/styles/pages/trinity-cert-tesol-course-page.scss';

const GapYearProgram = () => {
  return (
    <Layout
      title="Gap Year in Spain: Teach English & Learn Spanish"
      description="Gain a Teaching Qualification, Get Real-World Experience Overseas and Become Fluent in Spanish, All in One Year"
    >
      <div className="trinity-cert-tesol-course-page trinity-cert-tesol-course-page--gap">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="calendar" />
                    <span className="c-text-14">3 - 9 months</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="clock" />
                    <span className="c-text-14">25 hours/week</span>
                  </div>
                  <div className="item__wrapper wrap-text">
                    <img src={iconColumn} alt="column" />
                    <span className="c-text-14">Barcelona</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">
                  Gap Year in Spain:
                  <br /> Teach English & Learn Spanish
                </h1>
                <p className="c-first-section__description">
                  Take part in the ultimate gap year experience and gain valuable skills.
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.applyNow" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  Combine Adventure, Learning, and Teaching
                </h2>
                <p className="c-text-18 description second-section__l">
                  If you want to take time out before going to college and are keen to explore the
                  world and earn some money, we provide the ultimate gap year experience for
                  independent-minded travellers. Includes an internationally recognised teaching
                  qualification, job support, and one year of Spanish classes & fun cultural
                  activities.
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This package is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to take time out before going to university and explore Europe.
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You are interested in languages.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You are interested in teaching.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You want to learn about other cultures.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want an unforgettable, formative experience that will open the doors to
                      future opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        Internationally recognised Trinity CertTESOL (TEFL) qualification
                      </h3>
                      <p className="c-text-14">
                        Accepted all over the world for English teaching jobs.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Become fluent in Spanish</h3>
                      <p className="c-text-14">
                        Up to nine months of lessons, to progress from beginner to proficient.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Work as an English teacher</h3>
                      <p className="c-text-14">
                        Take advantage of our job support service and earn during your time in
                        Spain.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Fun Cultural Activities</h3>
                      <p className="c-text-14">
                        Includes everything from paella cooking classes to flamenco shows to paddle
                        boarding on the beach!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgReview} alt="" className="img-main" />
                    <img src={imgDots} alt="" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="" />
                    <p>
                      Doing a gap year in Spain was seriously the best decision ever! The staff was
                      so nice and welcoming, and the classes were super fun and well-organized. I
                      made amazing friends, tried things I never thought I’d do, and even taught
                      English at a language school! I learned so much Spanish, and it’s opened up a
                      whole new world for me. 100% recommend!
                      <br />
                      <strong>Grace & Yhinan</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s Included?</h2>
                  <h3 className="c-title-22">TEFL Course & Job Support</h3>
                  <br />
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        160-hour Trinity CertTESOL course and qualification.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Expert tuition from professional teachers.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Dedicated course coordinator.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Job contacts and placement help - many local schools hire directly from TEFL
                        Iberia graduates.
                      </p>
                    </div>
                  </div>
                  <h3 className="c-title-22">Spanish Classes & Cultural Activities</h3>
                  <br />
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        10-20 hours per week of Spanish classes in our accredited school.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Fun daily cultural activities & weekly excursion program.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">DELE exam preparation</p>
                    </div>
                  </div>
                  <h3 className="c-title-22">Accommodation, Visa & Bureaucracy Support</h3>
                  <br />
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Accommodation placement during your time in Spain. 3, 6, or 9 month options.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Placement in a shared apartment with your other classmates
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Assistance in gathering all your documents, applying for the visa and
                        support with local bureaucracy once you arrive.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Local sim card and help opening a bank account.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Airport transfer in Barcelona.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Welcome orientation sessions.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Ongoing support from our school staff.</p>
                    </div>
                  </div>
                  <h3 className="c-title-32">Price</h3>
                  <br />
                  <div className="block_r__price block_r__price--full block_r__price--column">
                    <h3 className="c-text-13">The Trailblazer: 3-Month Experience</h3>
                    <ul>
                      <li>Includes all of the above</li>
                      <li>20 hours per week of Spanish classes.</li>
                      <li>3 months of accommodation.</li>
                    </ul>
                    <span className="notion">
                      Note: If you only travel for 3 months you will not need a student visa.
                    </span>
                    <h3 className="c-title-32">
                      <span className="unaccent">Full Price:</span> <span>8,235€</span>
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--full block_r__price--column">
                    <h3 className="c-text-13">The Explorer: 6-Month Experience</h3>
                    <ul>
                      <li>Includes all of the above</li>
                      <li>10 hours per week of Spanish classes.</li>
                      <li>8 week English teaching internship.</li>
                      <li>6 months of accommodation.</li>
                    </ul>
                    <h3 className="c-title-32">
                      <span className="unaccent">Full Price:</span> <span>10,780€</span>
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--full block_r__price--column">
                    <h3 className="c-text-13">The Pioneer: 9-Month Experience</h3>
                    <ul>
                      <li>Includes all of the above</li>
                      <li>10 hours per week of Spanish classes.</li>
                      <li>3 - 6 months of paid teaching work.</li>
                      <li>9 months of accommodation.</li>
                    </ul>
                    <h3 className="c-title-32">
                      <span className="unaccent">Full Price:</span> <span>15,090€</span>
                    </h3>
                  </div>

                  <div className="block_r__btns">
                    <FormattedMessage id="url.apply.index">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          <FormattedMessage id="menu.applyNow" />
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                    <Link to="/gap-year-brochure" className="c-btn c-btn--red-border">
                      <FormattedMessage id="homepage.sec6.downloadBrochure" />
                      <IconArrowRightRed />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="c-section-py home-reviews">
          <div className="container">
            <Script
              src="https://static.elfsight.com/platform/platform.js"
              data-use-service-core
              defer
            ></Script>
            <div class="elfsight-app-eee49a0a-ddff-4bff-86f0-a4d4f59b2b45"></div>
          </div>
        </section> */}
        <section className="second-section special-section c-section-py">
          <div className="container">
            <div className="wrapper">
              <div className="row top">
                {/* <div className="col-md-6"> */}
                <div className="item">
                  <h2 className="item__title">Gap Year Experience Dates</h2>
                  <div className="item__description">
                    <div className="c-benefit-list second-section__l">
                      <h4 className="c-text-16">
                        We offer 3 intakes annually for our Gap Year Experience:
                      </h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">June 23rd 2025</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">September 3rd 2025</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">January 3rd 2026</p>
                      </div>
                    </div>
                    <p className="c-text-18 description second-section__l">
                      You can choose between the 3 month, 6 month or 9 month experience.
                    </p>
                  </div>
                </div>
                {/* </div> */}
                {/* <div className="col-md-6">
                  <div className="item">
                    <h2 className="item__title">
                      Are You Ready To Experience Barcelona To The Fullest?
                    </h2>
                    <p className="item__description">
                      Our jam-packed cultural activity program includes a huge variety of fun
                      activities: beach trips, paella cooking classes, local cultural events, wine
                      tasting, Spanish guitar sessions, flamenco, bike tours, beach yoga, paddle
                      boarding, weekend trips, etc. We guarantee you won’t be bored during your time
                      in Barcelona!
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="grid-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="block_l">
                <h2 className="block_l__title">
                  Are You Ready To Experience Barcelona To The Fullest?
                </h2>
                <p className="block_l__description">
                  Our jam-packed cultural activity program includes a huge variety of fun
                  activities: beach trips, paella cooking classes, local cultural events, wine
                  tasting, Spanish guitar sessions, flamenco, bike tours, beach yoga, paddle
                  boarding, weekend trips, etc. We guarantee you won’t be bored during your time in
                  Barcelona!
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="grid-list">
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid1} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid2} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid3} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid4} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid5} alt="imgGrid1" />
              </div>
              <div className="grid-item">
                <img className="grid-item__img" src={imgGrid6} alt="imgGrid1" />
              </div>
            </div>
          </div>
        </section>
        <section className="fifth-section c-student-support-section c-student-support-section--special">
          <div className="container">
            <h2 className="c-title-34">Safety & Support</h2>
            <p className="c-text-18 c-student-support-section__description">
              Choosing TEFL Iberia means travelling with confidence. Here’s how we ensure you feel
              safe and supported during your time in Spain:
            </p>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="" />
                  <h3 className="c-title-22">On-Arrival Assistance</h3>
                  <p className="c-text-16">
                    You’ll get picked up at the airport and one of our coordinators will be at your
                    accommodation to welcome you and help you get settled in.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="" />
                  <h3 className="c-title-22">Dedicated Support Team</h3>
                  <p className="c-text-16">
                    Our experienced staff and coordinators are available to assist with any
                    questions or concerns you have, whether it’s about the course, accommodation,
                    learning Spanish or exploring career opportunities. You’ll have regular contact
                    with your dedicated coordinator to make sure everything is going well.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <img src={imgDots} className="dots" alt="" />
                <div className="item">
                  <img src={iconCulture} alt="" />
                  <h3 className="c-title-22">24/7 Emergency Contact</h3>
                  <p className="c-text-16">
                    In case of emergencies, our local team will be on hand to provide guidance and
                    assistance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <img src={iconBureaucracy} alt="" />
                  <h3 className="c-title-22">Visa and Bureaucracy Help</h3>
                  <p className="c-text-16">
                    We support you at every step, from the visa application, getting medical
                    insurance and gathering your travel documents, to local bureaucracy in Spain and
                    help with translations.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="item">
                  <img src={iconEnvironment} alt="" />
                  <h3 className="c-title-22">Supportive & Inclusive Environment</h3>
                  <p className="c-text-16">
                    Our academy is located in a central, safe neighbourhood of Barcelona with
                    excellent local services. We foster an inclusive and respectful atmosphere,
                    where everyone feels comfortable to learn and grow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section">
          <AplicationProcess
            renderTitle={'The TEFL & Spanish Gap Year Application Process'}
            renderThirdContent={
              'Once you pass your test, you will be matched with a course coordinator for a call to discuss the entire experience: the TEFL course, Spanish lessons, accommodation, activities, visas, etc. You can also take the opportunity to ask as many questions as you like, and your parents are welcome to join the call too. '
            }
            renderFourthTitle={'Enrolment'}
            renderFourthContent={
              'We will organise tuition payment and your place on the program will be confirmed. We will then start on your visa application and paperwork, if required. '
            }
            renderFifthTitle={'The experience begins!'}
            renderFifthContent={
              'You’ll begin a life changing experience of living and studying in Barcelona, enjoying everything this beautiful place has to offer. '
            }
          />
        </section>
      </div>
    </Layout>
  );
};

export default GapYearProgram;
