import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';

const PricingCard = ({ data }) => {
  return (
    <div className="card">
      <h3 className="card__title">{data.courseTitle}</h3>
      <p className="card__subtitle">{data.courseSubtitle}</p>
      <div className="pricing">
        <div className="card__price card__price--primary">
          <h3 className="c-text-13">{data.primaryPriceName}</h3>
          <h3 className="c-title-32">
            {data.primaryPrice} <span className="period">{data.primaryPeriod || ''}</span>
          </h3>
        </div>
        {data.secondaryPrice && data.secondaryPriceName && (
          <div className="card__price card__price--secondary">
            <h3 className="c-text-13">{data.secondaryPriceName}</h3>
            <h3 className="c-title-32">
              {data.secondaryPrice} <span className="period">{data.secondaryPeriod || ''}</span>
            </h3>
          </div>
        )}
        {data.tertiaryPrice && data.tertiaryPriceName && (
          <div className="card__price card__price--secondary">
            <h3 className="c-text-13">{data.tertiaryPriceName}</h3>
            <h3 className="c-title-32">
              {data.tertiaryPrice} <span className="period">{data.tertiaryPeriod || ''}</span>
            </h3>
          </div>
        )}
      </div>
      <ul className="card__list">
        <li className="item">{data.notice}</li>
        {data.notice2 && <li className="item">{data.notice2}</li>}
        {data.notice3 && <li className="item">{data.notice3}</li>}
      </ul>
      <div className="card__btns">
        {data.btnDates && (
          <a href="#courses-cards" className="c-btn c-btn--red-border">
            <FormattedMessage id="pricingCard.btn.checkDates" />
            <IconArrowRightRed />
          </a>
        )}
        {/* <Link to="/apply/" className="c-btn c-btn--red">
          Apply Now
          <IconArrowRight />
        </Link> */}

        <FormattedMessage id="url.apply.index">
          {(locale) => (
            <Link to={locale[0]} className="c-btn c-btn--red">
              <FormattedMessage id="menu.applyNow" />
              <IconArrowRight />
            </Link>
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default PricingCard;
