import React, { useState, useMemo } from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../../components/Layout';
import PricingCard from '../../../components/PricingCard';
import ForthcomingCourses from '../../../components/ForthcomingCourses';

import useLocale from '../../../hooks/useLocale';

// import IconArrowRight from '../../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../../assets/images/svg/arrow-right-red.inline.svg';
import imgPhoto_r from '../../../assets/images/dates-and-fees/Photo 1.jpg';

import '../../../assets/styles/pages/dates-and-fees-page.scss';

const cardData = [
  {
    id: 1,
    courseTitle: 'Trinity CertTESOL Course',
    courseSubtitle: 'Barcelona',
    primaryPriceName: 'EARLY BIRD PRICE:',
    primaryPrice: '1650€',
    secondaryPriceName: 'FULL PRICE:',
    secondaryPrice: '1750€',
    notice: 'Early bird price applies if you pay in full 4 weeks in advance.',
    btnDates: true,
  },
  // {
  //   id: 2,
  //   courseTitle: 'Part-Time Online Trinity CertTESOL Course',
  //   courseSubtitle: 'Online',
  //   primaryPriceName: 'EARLY BIRD PRICE:',
  //   primaryPrice: '1400€',
  //   secondaryPriceName: 'FULL PRICE:',
  //   secondaryPrice: '1550€',
  //   notice: 'Early bird price applies if you pay in full 4 weeks in advance.',
  //   btnDates: true,
  // },
  {
    id: 5,
    courseTitle: 'Developing Teacher & Spanish Course',
    courseSubtitle: '1-Year Student visa',
    secondaryPriceName: '12 MONTHS:',
    secondaryPrice: '3200€',
    // secondaryPeriod: '/month',
    primaryPriceName: '6 MONTHS:',
    primaryPrice: '2000€',
    // primaryPeriod: '/year',
    notice: 'Flexible start dates.',
    btnDates: false,
  },
  {
    id: 3,
    courseTitle: 'Developing Teacher Course',
    courseSubtitle: 'Barcelona',
    primaryPriceName: '6 MONTHS: ',
    primaryPrice: '180€',
    // primaryPeriod: '/year',
    secondaryPriceName: '12 MONTHS: ',
    secondaryPrice: '300€ ',
    // secondaryPeriod: '/month',
    notice: 'Flexible start dates.',
    btnDates: false,
  },
  {
    id: 4,
    courseTitle: 'Gap Year Program',
    courseSubtitle: 'Full package including course, accommodation, visa, etc. ',
    primaryPriceName: '3 MONTHS: ',
    primaryPrice: '8235€',
    // primaryPeriod: '/year',
    secondaryPriceName: '6 MONTHS:',
    secondaryPrice: '10780€',
    tertiaryPriceName: '12 MONTHS:',
    tertiaryPrice: '15090€',
    // secondaryPeriod: '/month',
    notice: 'June 23rd 2025',
    notice2: 'September 3rd 2025',
    notice3: 'January 3rd 2026',
    btnDates: false,
  },
  // {
  //   id: 4,
  //   courseTitle: 'Teaching Young Learners',
  //   courseSubtitle: 'Extension Course',
  //   primaryPriceName: 'FULL PRICE:',
  //   primaryPrice: '300€',
  //   notice: 'Flexible start dates.',
  //   btnDates: false,
  // },
];

const cardDataEs = [
  {
    id: 1,
    courseTitle: 'Curso Trinity CertTESOL',
    courseSubtitle: 'Barcelona o Sevilla',
    primaryPriceName: 'PRECIO ANTICIPADO:',
    primaryPrice: '1500€',
    secondaryPriceName: 'PRECIO TOTAL:',
    secondaryPrice: '1650€',
    notice:
      'El precio anticipado se aplica si pagas la totalidad del curso con 4 semanas de anticipación.',
    btnDates: true,
  },
  // {
  //   id: 2,
  //   courseTitle: 'Part-Time Online Trinity CertTESOL Course',
  //   courseSubtitle: 'Online',
  //   primaryPriceName: 'EARLY BIRD PRICE:',
  //   primaryPrice: '1400€',
  //   secondaryPriceName: 'FULL PRICE:',
  //   secondaryPrice: '1550€',
  //   notice: 'Early bird price applies if you pay in full 4 weeks in advance.',
  //   btnDates: true,
  // },

  {
    id: 3,
    courseTitle: 'Curso Docente Avanzado',
    courseSubtitle: 'Visa de Estudiante de 1 Año',
    primaryPriceName: 'PRECIO ANTICIPADO:',
    primaryPrice: '1500€',
    primaryPeriod: '/año',
    secondaryPriceName: 'PRECIO NORMAL:',
    secondaryPrice: '150€',
    secondaryPeriod: '/mes',
    notice: 'Fecha de inicio flexible.',
    btnDates: false,
  },
  // {
  //   id: 4,
  //   courseTitle: 'Teaching Young Learners',
  //   courseSubtitle: 'Extension Course',
  //   primaryPriceName: 'FULL PRICE:',
  //   primaryPrice: '300€',
  //   notice: 'Flexible start dates.',
  //   btnDates: false,
  // },
  {
    id: 5,
    courseTitle: 'Curso Docente Avanzado + Programa de Catalán',
    courseSubtitle: '1-Year Student visa',
    secondaryPriceName: 'FULL PRICE:',
    secondaryPrice: '275€',
    secondaryPeriod: '/month',
    primaryPriceName: 'DISCOUNT  PRICE:',
    primaryPrice: '3000€',
    primaryPeriod: '/year',
    notice: 'Flexible start dates.',
    btnDates: false,
  },
];

const ActiveForthcomingCourses = ({ courseType, staticCourseTypeName, allCourses }) => {
  const [isMore, setIsMore] = useState(false);

  return (
    <div className={`inner-wrapper ${courseType === staticCourseTypeName ? 'active' : ''}`}>
      <ForthcomingCourses cardData={allCourses.slice(0, isMore ? allCourses.length : 4)} />
      <div className="notification">
        <p>
          {/* Developing Teacher course and Young Learners course have flexible start dates. Contact us
          for{' '}
          <Link to="/contact/" className="colored">
            more information.
          </Link> */}
          <FormattedMessage
            id="activeComingCourses.notice"
            values={{
              accentedLink: (chunk) => (
                <FormattedMessage id="url.contact.index">
                  {(locale) => (
                    <Link to={locale[0]} className="colored">
                      {chunk}
                    </Link>
                  )}
                </FormattedMessage>
              ),
            }}
          />
        </p>
      </div>
      {allCourses.length > 4 ? (
        <button
          onClick={() => setIsMore(!isMore)}
          className="c-btn c-btn--red-border load-more-dates"
        >
          {isMore ? (
            <FormattedMessage id="forthcomingCourses.active.showLess" />
          ) : (
            <FormattedMessage id="forthcomingCourses.active.loadMore" />
          )}
          <IconArrowRightRed />
        </button>
      ) : null}
    </div>
  );
};

const DatesAndFees = ({ data }) => {
  const [courseType, setCourseType] = useState('barcelona');
  const { enLocale } = useLocale({ matchPathName: '/es/' });
  // const [isMore, setIsMore] = useState(false);

  const allPrismicCourses = data.allPrismicCourses.edges;

  const trinityCourses = useMemo(
    () =>
      allPrismicCourses.filter(({ node: { data } }) =>
        data.show_on_these_pages.some(
          ({ page }) =>
            page === 'trinity-cert-tesol-course' ||
            page === 'blended-trinity-cert-tesol-course' ||
            page === 'online-trinity-course'
        )
      ),
    [allPrismicCourses]
  );
  const trinityBarcelonaAndOnlineCourses = useMemo(
    () =>
      trinityCourses.filter(
        ({
          node: {
            data: { location, show_on_these_pages },
          },
        }) =>
          location === 'barcelona' ||
          location === null ||
          show_on_these_pages.some(({ page }) => page === 'online-trinity-course')
      ),
    [trinityCourses]
  );

  // const trinityOnlineCourses = useMemo(
  //   () =>
  //     allPrismicCourses.filter(({ node: { data } }) =>
  //       data.show_on_these_pages.some(({ page }) => page === 'online-trinity-course')
  //     ),
  //   [allPrismicCourses]
  // );

  const localizedCardData = enLocale ? cardData : cardDataEs;
  return (
    <Layout
      title={{ id: 'dates&fees.seo.title' }}
      description={{ id: 'dates&fees.seo.description' }}
    >
      <div className="dates-and-fees-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="dates&fees.sec1.title" />
            </h1>
          </div>
        </section>
        <section className="second-section c-section-py">
          <div className="container">
            <h2 className="second-section__title c-title-34">
              <FormattedMessage id="dates&fees.sec2.title" />
            </h2>
            <div className="row">
              {localizedCardData.map((item) => {
                return (
                  <div key={item.id} className="col-md-6 col-12">
                    <PricingCard data={item} />
                  </div>
                );
              })}
            </div>
            <div className="row options">
              <div className="table-content">
                <div className="line top">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.optionalExtras" />
                  </p>
                  <p className="second">
                    <FormattedMessage id="dates&fees.sec2.price" />
                  </p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.accInBarcelona" />
                  </p>
                  <p className="second">750€</p>
                </div>
                <div className="line">
                  <p className="first">
                    12 Weeks Accommodation in Barcelona
                    <b className="save">Save 300€!</b>
                  </p>
                  <p className="second">1950€</p>
                </div>

                <div className="line">
                  <p className="first">
                    {' '}
                    <FormattedMessage id="dates&fees.sec2.airportPickUp" />
                  </p>
                  <p className="second">70€</p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.culturalActivity" />
                  </p>
                  <p className="second">50€</p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.nie" />
                  </p>
                  <p className="second">120€</p>
                </div>
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.service" />
                  </p>
                  <p className="second">40€</p>
                </div>
                {/* <div className="line">
                  <p className="first">
                    Spanish Taster Course <span className="toLowerCase">(6 hours)</span>
                  </p>
                  <p className="second">60€</p>
                </div>
                <div className="line">
                  <p className="first">
                    Intensive Spanish Course <span className="toLowerCase">(6 hours/week)</span>
                  </p>
                  <p className="second">
                    200€ <span className="period">/month</span>
                  </p>
                </div> */}
                <div className="line">
                  <p className="first">
                    <FormattedMessage id="dates&fees.sec2.teachingYL" />
                  </p>
                  <p className="second">
                    300€
                    {/*  <span className="period">/month</span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="container block_l">
              <div className="row no-gutters">
                <div className="col-xl-12 offset-xl-2">
                  <div className="content">
                    <h2 className="c-title-34">
                      <FormattedMessage id="dates&fees.sec3.title" />
                    </h2>
                    <ul className="content__list">
                      <li>
                        <FormattedMessage id="dates&fees.sec3.benefit1" />
                      </li>
                      <li>
                        <FormattedMessage id="dates&fees.sec3.benefit2" />
                      </li>
                      <li>
                        {/* Read more about our{' '}
                        <Link className="colored" to="/courses/tefl-course-payment-plan/">
                          flexible payment plan.
                        </Link> */}
                        <FormattedMessage
                          id="dates&fees.sec3.benefit3"
                          values={{
                            accentedLink: (chunk) => (
                              <FormattedMessage id="url.courses.tefl-course-payment-plan">
                                {(locale) => (
                                  <Link className="colored" to={locale[0]}>
                                    {chunk}
                                  </Link>
                                )}
                              </FormattedMessage>
                            ),
                          }}
                        />
                      </li>
                    </ul>
                    <p className="c-text-18">
                      <FormattedMessage
                        id="dates&fees.sec3.description"
                        values={{
                          accentedLink: (chunk) => (
                            <FormattedMessage id="url.apply.terms">
                              {(locale) => (
                                <Link className="colored text-fw-600" to={locale[0]}>
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </p>
                  </div>
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgPhoto_r} alt="imgPhoto_r" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="courses-section">
            <div className="container" id="courses-cards">
              <h1 className="courses-section__title c-title-34">
                <FormattedMessage id="dates&fees.cs.title" />
              </h1>
              {/* <div className="courses-section__btns">
                <button
                  onClick={() => setCourseType('barcelona')}
                  className={`btn ${courseType === 'barcelona' ? 'btn--active' : ''}`}
                >
                  <FormattedMessage id="dates&fees.cs.barcelona" />
                </button>
                <button
                  onClick={() => setCourseType('sevillie')}
                  className={`btn ${courseType === 'sevillie' ? 'btn--active' : ''}`}
                >
                  <FormattedMessage id="dates&fees.cs.sevillie" />
                </button>
                <button
                  onClick={() => setCourseType('online')}
                  className={`btn ${courseType === 'online' ? 'btn--active' : ''}`}
                >
                  Online
                </button>
              </div> */}
              <ActiveForthcomingCourses
                courseType={courseType}
                staticCourseTypeName={'barcelona'}
                allCourses={trinityBarcelonaAndOnlineCourses}
              />
              {/* <ActiveForthcomingCourses
                courseType={courseType}
                staticCourseTypeName={'sevillie'}
                allCourses={trinitySevilleCourses}
              /> */}
              {/* <ActiveForthcomingCourses
                courseType={courseType}
                staticCourseTypeName={'online'}
                allCourses={trinityOnlineCourses}
              /> */}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
            show_on_these_pages {
              page
            }
            location
            urgency_text {
              text
            }
          }
        }
      }
    }
  }
`;

export default DatesAndFees;
