import React from 'react';
import { FormattedMessage } from 'react-intl';

import Layout from '../../components/Layout';
import useLocale from '../../hooks/useLocale';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const TermsAndConditions = () => {
  const { enLocale } = useLocale({ matchPathName: '/es/' });

  return (
    <Layout title={{ id: 'terms.seo.title' }} description={{ id: 'terms.seo.description' }}>
      <div className="terms-and-privacy-page">
        <div className="container container--wide-padding">
          <div className="wrapper">
            <h1 className="c-title-42">
              <FormattedMessage id="terms.sec1.title" />
            </h1>
            <h2 className="subsection__title">Trinity CertTESOL - In-person and online courses</h2>
            <ol className="subsection">
              <h2 className="subsection__title subsection__title--sm">
                Course payment, cancellations and refunds
              </h2>
              <li className="subsection__par">
                Once accepted onto the course a 450€ deposit will be required to reserve your place.
                You will only be accepted onto the course upon receipt of your deposit.
              </li>
              <li className="subsection__par">The 450€ deposit is non-refundable.</li>
              <li className="subsection__par">
                We reserve the right to cancel your application for non-payment.
              </li>
              <li className="subsection__par">
                The total balance of course fees must be paid 1 month prior to the course start
                date. Should the balance not be paid within this time frame your position in the
                course will become available to other applicants if the course meets capacity, and
                your position will be forfeited. Your deposit can be transferred to an alternative
                start date.
              </li>
              <li className="subsection__par">
                If you cancel or postpone your course:
                <ol>
                  <li>
                    More than 4 weeks notice - you will receive no penalty and your deposit and
                    additional paid course fees will be valid for a future course within 12 months,
                    if you wish to cancel you will be entitled to a full refund minus your deposit.
                  </li>
                  <li>
                    Less than 4 weeks notice - your deposit and additional paid course fees will be
                    valid for a future course within 12 months. If you choose to cancel, you will
                    forfeit your deposit. Additionally, 50% of any remaining course fees you have
                    paid (excluding the deposit) will also be non-refundable. The cut off point is
                    5pm Spanish time, on the Thursday preceding the Monday course start date.
                  </li>
                </ol>
              </li>
              <li className="subsection__par">
                Once the course has started, no refunds or postponements will be offered.
              </li>
              <li className="subsection__par">
                In the case of refunds, any fees owed will be returned to you within 90 days of the
                refund request and confirmation.
              </li>
              <li className="subsection__par">
                TEFL Iberia reserves the right to cancel a course which does not meet the minimum
                number of students. In case of any cancellation by TEFL Iberia of a course which has
                not yet started, students will be reimbursed the full amount for the reservation or
                any other payment that has been made in advance. If appropriate, TEFL Iberia will
                give the student the opportunity to carry out the course in a different format,
                either online or in person.
              </li>
              <li className="subsection__par">
                A fee of €80 is applicable for any failed assessments for re-submission.
              </li>
              <li className="subsection__par">
                If you start a course and for any reason need to change to a later course date,
                (sickness, accident, unforeseen circumstances, etc) you will be required to pay the
                Trinity moderation fee.
              </li>
              <li className="subsection__par">
                TEFL Iberia reserves the right to use their own discretion regarding refunds and
                review them on a case by case basis.
              </li>
              <li className="subsection__par">
                Where a refund is given any bank charges related to transfers and exchange rate
                movements will be deducted from the refundable amount due.
              </li>
              <li className="subsection__par">
                If you sign up to TEFL Iberia via an agency, any refunds will be subject to the
                agency’s terms and conditions.
              </li>
              <li className="subsection__par">
                Online courses only: students are required to declare they have appropriate
                resources and environment to undertake an online course. This includes the
                appropriate hardware (laptop/computer) that has sufficient video and sound (speaker
                and microphone) capabilities along with sufficient internet connection. It is also
                required that students are in a quiet and well lit space.
              </li>
            </ol>

            <h2 className="subsection__title">
              Developing Teacher & Spanish Course and Student Visas
            </h2>
            <ol className="subsection">
              <li className="subsection__par">
                Once accepted onto the course, a non-refundable 10% deposit will be required to
                reserve your place. If you are signing up to two courses (e.g. Trinity CertTESOL and
                Developing Teacher & Spanish), a non-refundable deposit is required for each course.
              </li>
              <li className="subsection__par">
                The total course balance must be paid 3 months in advance of your course start date,
                in order to ensure you receive your visa on time.
              </li>
              <li className="subsection__par">
                The total course balance must be paid in advance prior to your visa appointment, in
                order to send you the appropriate course registration letter and proof of payment.
              </li>
              <li className="subsection__par">
                Once the course registration letter (for an initial visa or renewal) has been
                issued, no course refunds can be made, unless you provide proof of rejection from
                the consulate. This is to prevent visa fraud.
              </li>
              <li className="subsection__par">
                If you require express shipping of the physical visa documentation you must pay the
                fees for shipping these documents in advance. This is typically 60-70€ and the
                letter will be sent by DHL courier.
              </li>
              <li className="subsection__par">
                If your visa is rejected, you will receive a refund of all fees paid for the
                Developing Teacher & Spanish course, except for a 300€ admin fee. If you have also
                signed up to the Trinity CertTESOL course, this will be treated independently and
                subject to its own terms and conditions (see above). You will need to send us a copy
                of the rejection letter before refunds can be processed.
              </li>
              <li className="subsection__par">
                If a student visa is processed and approved, no refunds will be issued.
              </li>
              <li className="subsection__par">
                If you would like to postpone your start date and notify us before you have
                submitted your application to the consulate, we will provided you with an updated
                enrolment letter free of charge. If you subsequently postpone again, we will charge
                a 50€ admin fee for each new letter we issue.
              </li>
              <li className="subsection__par">
                Once you have submitted your application to the consulate, it will not be possible
                to make any changes.
              </li>
              <li className="subsection__par">
                Visa renewals:
                <ol>
                  <li>
                    Once you have completed your studies you may be able to renew your visa. You
                    must attend 60% of the Spanish course to receive a course certificate and letter
                    of completion (certificado de aprovechamiento).
                  </li>
                  <li>
                    Visa renewals adhere to the same terms and conditions as new sign ups: you must
                    pay in full in order to get the enrolment letter, and 10% of the fee is
                    non-refundable in case of cancellation. No refunds will be given once the
                    enrolment letter has been issued. This measure is in place to prevent visa
                    fraud.
                  </li>
                  <li>
                    If there is a delay in your visa approval and your visa is rejected, you must
                    still pay for the course in the intermittent time. For example, if you apply for
                    a visa in January but it is rejected in June, you must pay the course balance
                    from January-June. A refund will only be given from the time after the rejection
                    date.
                  </li>
                </ol>
              </li>
              <li className="subsection__par">
                In the case of refunds, any fees owed will be returned to you within 90 days of the
                refund request and confirmation.
              </li>
              <li className="subsection__par">
                The Developing Teacher & Spanish courses can be moved online at the school’s
                discretion.
              </li>
              <li className="subsection__par">
                During a 12 month student visa, you will have 4 weeks of summer holiday in August, 3
                weeks of holiday at Christmas, and 1 week of holiday at Easter. These times are
                considered holidays and will not be recovered.
              </li>
              <li className="subsection__par">
                If a Developing Teacher & Spanish class falls on a public holiday, the class will
                not be recovered.
              </li>
              <li className="subsection__par">
                Classes missed due to a student’s absence cannot be recovered.
              </li>
              <li className="subsection__par">
                If the number of students in a group is reduced to less than 4, TEFL Iberia reserves
                the right to make adjustments (reset time, reduced hours, integrate into another
                group, organise alternative study activities).
              </li>
              <li className="subsection__par">
                We reserve the right to cancel a course up to 5 days before the start date. In this
                case, your fees and deposit will be refunded in full. Students are advised to have
                cancellation insurance for their flights.
              </li>
              <li className="subsection__par">
                The school can substitute teachers for these classes as they see fit.
              </li>
            </ol>

            {/* Accommodation */}
            <h2 className="subsection__title">Accommodation</h2>
            <ol className="subsection">
              <li className="subsection__par">
                The accommodation service is for 4 weeks (27 nights). Check in is the day before
                your course starts (Sunday) and check out is the day after your course finishes
                (Saturday)
              </li>
              <li className="subsection__par">
                Students are required to notify TEFL Iberia of accommodation needs at least 4 weeks
                prior to arrival.
              </li>
              <li className="subsection__par">
                If accommodation is requested less than 4 weeks before the course start date, TEFL
                Iberia cannot guarantee accommodation.
              </li>
              <li className="subsection__par">
                Accommodation fees must be paid before the confirmation of accommodation at least 4
                weeks prior to course start date or arrival. TEFL Iberia will not make any
                accommodation arrangements until full payment has been received. The accommodation
                fee includes a 100€ non-refundable deposit.
              </li>
              <li className="subsection__par">
                TEFL Iberia can not make any guarantees on location, flat type or included
                amenities.
              </li>
              <li className="subsection__par">
                In Barcelona and Europe in general, the buildings are older and smaller than you may
                be accustomed to in your home country. We provide accommodation in line with the
                standards expected within Spain. TEFL Iberia can’t change accommodation based on
                personal tastes and preferences.
              </li>
              <li className="subsection__par">
                Students will be expected to pay a refundable €200 security deposit directly to the
                accommodation host when they arrive.This will be refunded to you when you leave,
                assuming there are no damages to the flat.
              </li>
              <li className="subsection__par">
                If there are damages to the flat, this is considered a dispute between the student
                and the host.
              </li>
              <li className="subsection__par">
                The 200€ security deposit can either be paid in cash or electronically, at the
                discretion of the host.
              </li>
              <li className="subsection__par">
                If, for any reason, a student cancels their accommodation, the following fees will
                be applied:
                <ol>
                  <li>
                    {' '}
                    More than 4 weeks of arrival: the student will be entitled to a full refund of
                    accommodation fees, minus the 100€ non-refundable deposit.
                  </li>
                  <li>
                    Within 4 weeks of arrival: the student will forfeit 50% of their accommodation
                    fees in order to cover host fees.
                  </li>
                  <li>
                    Within 2 weeks of arrival: the student will forfeit all of their accommodation
                    fees in order to cover host fees.{' '}
                  </li>
                </ol>
              </li>
              <li className="subsection__par">
                If, for any reason, including visa processing delays, a student changes or postpones
                their accommodation date, the following fees will be applied:
                <ol>
                  <li>More than 4 weeks of arrival: a 50€ admin fee.</li>
                  <li>
                    Within 4 weeks of arrival: 50% of their accommodation fee in order to cover host
                    fees.
                  </li>
                  <li>
                    Within 2 weeks of arrival: The full accommodation fees will need to be repaid,
                    in order to cover host fees.
                  </li>
                </ol>
              </li>
              <li className="subsection__par">
                If you arrive late due to sickness, visa delay, accidents, etc, you will not be
                entitled to any unused accommodation days. For example, if you arrive 7 days late,
                those days cannot be recovered and you will not be entitled to a refund for those
                days.
              </li>
              <li className="subsection__par">
                If a student arrives after 10pm, a 30€ late arrival fee will be charged by the host.
              </li>
              <li className="subsection__par">
                If you have booked two or more months of accommodation with us, we may provide you
                with more than one host for the duration of your stay.
              </li>
              <li className="subsection__par">
                The host cannot be held responsible for loss or theft of personal belongings. We
                recommend students take out the appropriate travel insurance for their personal
                items.
              </li>
              <li className="subsection__par">
                Once the student has arrived and for any reason decides not to stay in the assigned
                flat, they will not be entitled to a refund. The security deposit will be refunded
                as long as there are no damages.
              </li>
              <li className="subsection__par">
                TEFL Iberia has the right to remove a student from the accommodation as a result of
                unacceptable behaviour that impacts the host or other guests. The student will not
                receive any reimbursement of fees if they are removed from the accommodation, and
                TEFL Iberia is not obliged to find alternative accommodation.
              </li>
            </ol>

            <h2 className="subsection__title">General</h2>
            <ol className="subsection">
              <li className="subsection__par">
                TEFL Iberia operates in a language academy and expects good conduct of behaviour. It
                is expected that students respect the academy, its staff and other students.
              </li>
              <li className="subsection__par">
                TEFL Iberia has the right to remove a student from the course and academy as a
                result of unacceptable behaviour that impacts staff and students. The student will
                not receive any reimbursement of course fees if they are removed from the course.
              </li>
              <li className="subsection__par">
                If you miss any of the course sessions due to ill health you will have to provide a
                doctor’s note.
              </li>
              <li className="subsection__par">
                Students are required to make a confidential declaration about any medical
                conditions (physical or mental) that may affect their performance on the course.
                This information is necessary to allow TEFL Iberia to make the necessary adjustments
                to ensure that students have a positive experience.
              </li>
              <li className="subsection__par">
                If you miss more than 2 days of the course you may forfeit your place on the course
                and will have to pay an admin fee of 100€ to make up the missed time on a future
                course.
              </li>
              <li className="subsection__par">
                TEFL Iberia will not be responsible for unsuccessful completion of the course and
                will not offer refunds for uncompleted courses and will use discretion on a case by
                case basis for the opportunity to re-attend the course.
              </li>
              <li className="subsection__par">
                The TEFL Iberia course certificate will be made available one month after course
                completion.
              </li>
              <li className="subsection__par">
                You can pick up your certificate from the school or we can post it to you for a fee
                of 15€, via standard post. We will store the certificate for you for up to 2 years,
                after which you will have to contact Trinity College London directly to get a
                replacement hard copy.
              </li>
              <li className="subsection__par">
                TEFL Iberia cannot be held responsible for loss or theft of personal belongings.
              </li>
              <li className="subsection__par">
                TEFL Iberia reserves the right to update and make changes to their Terms and
                Conditions as they see fit.
              </li>
            </ol>
            {/* <h2 className="subsection__title">
              <FormattedMessage id="terms.sub5.title" />
            </h2>
            <ul className="subsection__list">
              <li className="item">
                <FormattedMessage id="terms.sub5.text1" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text2" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text3" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text4" />
              </li>

              <li className="item">
                <FormattedMessage id="terms.sub5.text5" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text6" />
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
